<template>
  <div
    v-if="$can('read', 'orders')"
    class="column is-12"
  >
    <Card :title="$tc('order', 2)">
      <template slot="actions">
        <b-input
          v-model="searchOrders"
          icon="magnify"
          size="is-small"
          :placeholder="$t('search')"
          rounded
          @input="ordersSearch"
        />
      </template>
      <b-table
        :data="ordersData"
        paginated
        :per-page="perPage"
        :current-page="currentPage"
        class="height-auto"
        default-sort="ordered_at"
        default-sort-direction="desc"
      >
        <b-table-column
          v-slot="props"
          field="id"
          :label="$t('id')"
          sortable
        >
          <a
            href="javascript:;"
            sortable
            @click="
              openDrawer('orderForm', {
                key: props.row.id,
                index: props.index,
                order_id: props.row.id,
                user_id: userId,
                site_id: props.row.site_id,
                warehouses: warehouses,
              })
            "
          >{{ props.row.id }}</a>
        </b-table-column>
        <b-table-column
          v-slot="props"
          field="total.value"
          :label="$t('total')"
          sortable
        >
          {{
            props.row.total ? props.row.total.formatted : "-"
          }}
        </b-table-column>
        <b-table-column
          v-slot="props"
          field="ordered_at.datetime"
          :label="$t('date')"
          sortable
        >
          <span>
            {{
              (props.row.ordered_at && props.row.ordered_at.formatted) ||
                "-"
            }}
            <span class="has-text-grey-light">{{
              props.row.ordered_at && props.row.ordered_at.time
            }}</span>
          </span>
        </b-table-column>
        <b-table-column
          v-slot="props"
          field="status"
          :label="$t('status')"
          sortable
        >
          {{
            props.row.status
          }}
        </b-table-column>
        <b-table-column
          v-slot="props"
          field="invoice"
          :label="$t('invoice')"
          width="80"
          centered
          sortable
        >
          <span
            v-if="props.row.invoice"
            @click="downloadInvoice(props.row.id)"
          >
            <b-icon
              icon="download"
              custom-size="mdi-18px"
              class="has-text-grey is-clickable"
            />
          </span>
          <span v-else>-</span>
        </b-table-column>
        <EmptyBlock
          slot="empty"
          icon="cart-outline"
        />
      </b-table>
      <Pagination
        class="p-0"
        :per-page="perPage"
        :total="ordersData.length"
        :current-page="currentPage"
        @update-per-page="val => (perPage = val)"
        @update-current-page="val => (currentPage = val)"
      />
    </Card>
  </div>
</template>

<script>
export default {
  props: {
    orders: { type: Array, required: true },
    warehouses: { type: Array, required: true },
    userId: { type: Number, required: true }
  },
  data() {
    return {
      searchOrders: "",
      ordersData: [],
      currentPage: 1,
      perPage: 5,
      perPages: [5, 20, 50]
    };
  },
  created() {
    this.ordersSearch();
    this.$bus.$on("update-order-options", params => {
      let item = this.orders.filter(i => i.id === params.id);
      let status = null;
      if (params.details && params.details.statuses) {
        status = params.details.statuses.filter(s => s.selected);

        // update status
        if (status.length > 0) item[0]["status"] = status[0]["status"];
      }
      // update total
      item[0]["total"] = params.summary.total;
      item[0]["details"] = params.details;
      item[0]["items"] = params.items;
      let index = this.orders.indexOf(item[0]);
      this.$set(this.orders, index, item[0]);
    });
  },
  destroyed() {
    this.$bus.$off("update-order-options");
  },
  methods: {
    ordersSearch() {
      if (this.searchOrders.length > 0) {
        let orders = [...this.orders];
        this.ordersData = orders.filter(obj => {
          let inId = `${obj.id}`.indexOf(this.searchOrders) != -1;
          let inTotal = obj.total
            ? obj.total.formatted.indexOf(this.searchOrders) != -1
            : false;
          let inOrderedAt = obj.ordered_at
            ? obj.ordered_at.formatted.indexOf(this.searchOrders) != -1
            : false;
          let inStatus = obj.status.indexOf(this.searchOrders) != -1;
          return inId || inTotal || inOrderedAt || inStatus;
        });
      } else {
        this.ordersData = this.orders;
      }
    }
  }
};
</script>
