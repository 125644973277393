<template>
  <div class="column is-12">
    <div class="columns is-multiline">
      <div class="column is-4">
        <Card>
          <div class="level-item has-text-centered m-4">
            <div>
              <p class="heading">
                {{ $t("cashback") }}
              </p>
              <p class="title">
                {{ stats.cashback[0] && stats.cashback[0].formatted || '-' }}
                <b-dropdown
                  v-if="stats.cashback.length > 1"
                  aria-role="list"
                  position="is-bottom-right"
                  :triggers="['hover']"
                  class="ml-2"
                >
                  <b-tag
                    slot="trigger"
                    size="is-small"
                    type="is-info is-light"
                    rounded
                  >
                    +{{ stats.cashback.length - 1 }}
                    <b-icon
                      icon="chevron-down"
                      size="is-small"
                      class="is-clickable ml-1"
                    />
                  </b-tag>

                  <template v-for="stat in extra_cashback">
                    <b-dropdown-item
                      :key="stat"
                      :focusable="false"
                      custom
                      aria-role="listitem"
                    >
                      {{ stat.formatted }}
                    </b-dropdown-item>
                  </template>
                </b-dropdown>
              </p>
            </div>
          </div>
        </Card>
      </div>
      <div class="column is-4">
        <Card>
          <div class="level-item has-text-centered m-4">
            <div>
              <p class="heading">
                {{ $tc("order", 2) }}
              </p>
              <p class="title">
                {{ stats.orders.shipped }}/{{ stats.orders.total }}
              </p>
            </div>
          </div> 
        </Card>
      </div>
      <div class="column is-4">
        <Card>
          <div class="level-item has-text-centered m-4">
            <div>
              <p class="heading">
                {{ $t("turnover") }}
              </p>
              <p class="title">
                {{ stats.turnover[0] && stats.turnover[0].formatted || '-' }}
                <b-dropdown
                  v-if="stats.turnover.length > 1"
                  aria-role="list"
                  position="is-bottom-right"
                  :triggers="['hover']"
                  class="ml-2"
                >
                  <b-tag
                    slot="trigger"
                    size="is-small"
                    type="is-info is-light"
                    rounded
                  >
                    +{{ stats.turnover.length - 1 }}
                    <b-icon
                      icon="chevron-down"
                      size="is-small"
                      class="is-clickable ml-1"
                    />
                  </b-tag>

                  <template v-for="stat in extra_turnover">
                    <b-dropdown-item
                      :key="stat"
                      :focusable="false"
                      custom
                      aria-role="listitem"
                    >
                      {{ stat.formatted }}
                    </b-dropdown-item>
                  </template>
                </b-dropdown>
              </p>
            </div>
          </div>
          <!-- <hr class="my-0"> -->
        </Card>
      </div>
    </div>
  </div>
</template>

<script>
import Card from '../../../layout/Card.vue';
export default {
  components: { Card },
  props: {
    stats: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      extra_turnover: [],
      extra_cashback: [],
    }
  },
  created() {
    this.extra_turnover = (this.stats.turnover.length > 1) ? this.stats.turnover.slice(1) : [];
    this.extra_cashback = (this.stats.cashback.length > 1) ? this.stats.cashback.slice(1) : [];
  },
};
</script>
