<template>
  <div class="columns is-multiline">
    <Mailing
      :user="user"
      :site="site"
    />
  </div>
</template>

<script>
import Mailing from "./Cards/Mailing";

export default {
  components: {
    Mailing
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
    site: {
      type: Object,
      required: true,
    },
  }
}
</script>

<style>

</style>
