<template>
  <div class="columns is-multiline">
    <div class="column is-12">
      <AnalyticsRanking
        has-title
        :site-id="profile.site_id"
        :total="total"
        :pagination-object="pagination"
        :rows-per-page="[10, 20, 50, 100]"
        :stats="statsAnalytics"
        :stats-loading="stats_loading"
        :headers="stats_headers"
        :warehouses="warehousesAnalytics"
        :origin-sites="originSites"
        :brands="brands"
        :salesmen="salesmenAnalytics"
        :origin-categories="originCategories"
        :original-data="originalData"
        @getData="loadUserData"
      />
    </div>
    <div class="column is-12">
      <AnalyticsGraph
        has-title
        :site-id="profile.site_id"
        :stats="data"
        :periods="periods"
        :brands-prop="graphBrands"
        :origin-categories="graphCategories"
        :warehouses="graphWarehouses"
        :origin-sites="graphSites"
        :salesmen="graphSalesmen"
        :loading-prop="loadingGraph"
        @getData="getGraphData"
      />
    </div>
  </div>
</template>

<script>
import AnalyticsRanking from "@/components/modules/analytics/ranking/index";
import AnalyticsGraph from "@/components/modules/analytics/graphs/index";

export default {
  components: {
    AnalyticsRanking,
    AnalyticsGraph
  },
  props: {
    profile: { type: Object, required: true }
  },
  data() {
    return {
      // User Analytics
      total: 0,
      pagination: {
        perPage: 50,
        page: 1
      },
      statsAnalytics: [],
      originalData: [],
      stats_loading: false,
      warehousesAnalytics: [],
      originSites: [],
      brands: [],
      salesmenAnalytics: [],
      originCategories: [],
      stats_headers: [
        {
          text: this.$tc("name", 1),
          value: "tld",
          width: 150,
          sticky: true
        },
        {
          text: this.$tc("order_short", 2),
          value: "num_orders"
        },
        {
          text: this.$t("gross_revenue"),
          value: "gross_revenue"
        },
        {
          text: this.$t("discount"),
          value: "discount"
        },
        {
          text: this.$t("net_revenue"),
          value: "net_revenue"
        },
        {
          text: this.$t("cashback"),
          value: "cashback"
        },
        {
          text: this.$t("purchase_price"),
          value: "purchase_price"
        },
        {
          text: this.$t("gross_margin"),
          value: "gross_margin"
        },
        {
          text: this.$t("gross_margin_ratio"),
          value: "gross_margin_ratio"
        },
        {
          text: this.$t("avg_cart"),
          value: "avg_cart"
        },
        {
          text: this.$t("qty"),
          value: "quantity"
        },
        {
          text: this.$t("qty_free"),
          value: "quantity_free"
        },
        {
          text: "ABC",
          value: "abc",
          centered: true,
          sticky: true
        }
      ],

      // user Graphs
      loadingGraph: false,
      data: [],
      periods: [],
      graphWarehouses: [],
      graphSites: [],
      graphBrands: [],
      graphCategories: [],
      graphSalesmen: []
    };
  },
  created() {
    //   this.getGraphData();
  },
  methods: {
    loadUserData(filters, options, download) {
      let id = this.$route.params.user_id;

      this.stats_loading = true;

      // adding selected warehouse to params
      let api_params = "?warehouse_id=" + (options.warehouse_id || "");

      // adding date range to params
      if (options.date_filter.from && options.date_filter.to) {
        api_params +=
          "&date_from=" +
          options.date_filter.from +
          "&date_to=" +
          options.date_filter.to;
      }

      // adding selected ranking value to params
      if (options.selected_ranking)
        api_params += "&ranking=" + options.selected_ranking;

      // adding pagination
      api_params += `&page=${options.pagination.page}&per_page=${options.pagination.perPage}`;

      // adding filters
      let filter = [];
      if (filters.brands.length) {
        filter.push({
          id: "brands",
          selected: filters.brands
        });
      }
      if (filters.sites.length) {
        let sites = [];
        let slds = [];
        for (let f of filters.sites) {
          if(typeof f == 'number') sites.push(f);
          else slds.push(parseInt(f.substring(1)));
        }
        if (sites.length) {
          filter.push({
            id: "sites",
            selected: sites
          });
        }
        if (slds.length) {
          filter.push({
            id: "slds",
            selected: slds
          });
        }
      }
      if (filters.slds.length) {
        filter.push({
          id: "slds",
          selected: filters.slds
        });
      }
      if (filters.salesmen) {
        filter.push({
          id: "salesmen",
          selected: [filters.salesmen]
        });
      }
      if (filters.categories.length) {
        filter.push({
          id: "categories",
          selected: filters.categories
        });
      }
      // encoding filter values
      api_params += `&filters=${btoa(JSON.stringify(filter))}`;

      if (download) {
        api_params += `&export=1`;
      }

      this.$axios
        .get(`/users/${id}/analytics` + api_params)
        .then(res => {
          if (download) {
            // downloading recieved file to user machine
            const url = window.URL.createObjectURL(
              new Blob([res.data.file], {
                type: "application/vnd.ms-excel"
              })
            );
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", res.data.filename);
            document.body.appendChild(link);
            link.click();
          } else {
            // applying new data recieved from API
            let data = res.data.list;
            this.warehousesAnalytics = res.data.filters.warehouses;
            this.brands = res.data.filters.brands;
            this.originCategories = res.data.filters.categories;
            this.originSites = res.data.filters.sites;
            this.salesmenAnalytics = res.data.filters.salesmen;
            this.originalData = data;
            this.total = res.data.pagination.total;
            this.pagination.page = res.data.pagination.current;
            let summary = res.data.summary;
            let stats = [];

            for (let i = 0; i < data.length; i++) {
              let elt = data[i];
              let obj = {
                id: elt.id,
                label: elt.label,
                num_orders: elt.stats.num_orders,
                gross_revenue: elt.stats.gross_revenue.formatted,
                discount: elt.stats.discount.formatted,
                net_revenue: elt.stats.net_revenue.formatted,
                cashback: elt.stats.cashback.formatted,
                avg_cart: elt.stats.avg_cart.formatted,
                quantity: elt.stats.quantity,
                abc: elt.stats.abc
              };
              if (elt.stats.purchase_price)
                obj.purchase_price = elt.stats.purchase_price.formatted;
              if (elt.stats.gross_margin)
                obj.gross_margin = elt.stats.gross_margin.formatted;
              if (elt.stats.gross_margin_ratio)
                obj.gross_margin_ratio = elt.stats.gross_margin_ratio;
              stats.push(obj);
            }
            let sum = {
              id: "summary",
              label: this.$t("total"),
              num_orders: summary.num_orders,
              gross_revenue: summary.gross_revenue.formatted,
              discount: summary.discount.formatted,
              net_revenue: summary.net_revenue.formatted,
              cashback: summary.cashback.formatted,
              avg_cart: summary.avg_cart.formatted,
              quantity: summary.quantity,
              abc: summary.abc
            };
            if (summary.purchase_price)
              sum.purchase_price = summary.purchase_price.formatted;
            if (summary.gross_margin)
              sum.gross_margin = summary.gross_margin.formatted;
            if (summary.gross_margin_ratio)
              sum.gross_margin_ratio = summary.gross_margin_ratio;
            stats.push(sum);
            this.statsAnalytics = JSON.parse(JSON.stringify(stats));
          }
        })
        .catch(e => {
          this.valid_account = false;
          this.clientError(e);
        })
        .finally(() => (this.stats_loading = false));
    },
    getGraphData(obj = {}) {
      let id = this.$route.params.user_id;

      this.loadingGraph = true;
      let api_params = `?warehouse_id=${obj.warehouse_id || ""}&period=${
        obj.selected_date_mode
      }`;

      let filter = [];
      if (obj.brands && obj.brands.length) {
        filter.push({
          id: "brands",
          selected: obj.brands
        });
      }
      if (obj.sites && obj.sites.length) {
        filter.push({
          id: "sites",
          selected: obj.sites
        });
      }
      if (obj.tlds && obj.tlds.length) {
        filter.push({
          id: "slds",
          selected: obj.tlds
        });
      }
      if (obj.categories && obj.categories.length) {
        filter.push({
          id: "categories",
          selected: obj.categories
        });
      }
      if (obj.salesmen && obj.salesmen.length) {
        filter.push({
          id: "salesmen",
          selected: obj.salesmen
        });
      }
      let dates_array = [
        {
          from: obj.date.from,
          to: obj.date.to
        }
      ];
      if (obj.compare_date && obj.compare_date.from && obj.compare_date.to) {
        dates_array.push({
          from: obj.compare_date.from,
          to: obj.compare_date.to
        });
      }
      filter.push({
        id: "dates",
        selected: dates_array
      });

      api_params += `&filters=${btoa(JSON.stringify(filter))}`;

      this.$axios
        .get(`users/${id}/graph` + api_params)
        .then(res => {
          this.periods = res.data.dataset.periods;
          this.data = res.data.dataset.data;
          this.graphWarehouses = res.data.filters.warehouses;
          this.graphSites = res.data.filters.sites;
          this.graphBrands = res.data.filters.brands;
          this.graphCategories = res.data.filters.categories;
          this.graphSalesmen = res.data.filters.salesmen;
        })
        .catch(e => this.clientError(e))
        .finally(() => (this.loadingGraph = false));
    }
  }
};
</script>
