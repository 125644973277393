<template>
  <div class="column is-12">
    <Card
      :title="$t('company_information')"
      header-class="mb-4"
    >
      <template
        v-if="isValidUser"
        slot="actions"
      >
        <span
          v-if="!company && $can('create', 'users.business')"
          @click="
            openDrawer('companyForm', {
              company: null,
              countries: countries,
              userId: userId,
              languages: languages,
              account_types: types,
              incoterms: incoterms,
              site: site,
              local_iso: localIso
            })
          "
        >
          <b-icon
            icon="plus"
            size="is-small"
            class="is-clickable"
          />
        </span>
        <span
          v-if="company && $can('update', 'users.business')"
          @click="
            openDrawer('companyForm', {
              company: company,
              countries: countries,
              userId: userId,
              languages: languages,
              account_types: types,
              incoterms: incoterms,
              site: site,
              local_iso: localIso,
              status: status,
            })
          "
        >
          <b-icon
            icon="pencil"
            size="is-small"
            class="is-clickable"
          />
        </span>
      </template>
      <div
        v-if="company"
        class="columns"
      >
        <div class="column is-6">
          <div class="columns is-multiline is-mobile is-variable is-1">
            <div class="column is-3 has-text-grey-light has-text-right">
              {{ $t("lname") }}
            </div>
            <div class="column is-9">
              {{ company.address.fname + " " + company.address.lname || "-" }}
            </div>
            <!-- <div class="column is-3 has-text-grey-light has-text-right">
              {{ $t("lname") }}
            </div>
            <div class="column is-9">
              {{ company.address.lname || "-" }}
            </div> -->
            <div class="column is-3 has-text-grey-light has-text-right">
              {{ $t("company") }}
            </div>
            <div class="column is-9">
              {{ company.company }}
            </div>
            <div class="column is-3 has-text-grey-light has-text-right">
              {{ $t("address") }}
            </div>
            <div
              v-if="company.address"
              class="column is-9"
            >
              {{ company.address.address1 }}
              <span v-if="company.address.address2">
                <br>
                {{ company.address.address2 }}
              </span>
              <br>
              {{ company.address.zip }}, {{ company.address.city }} -
              {{ company.address.country.country }}
            </div>
            <div class="column is-3 has-text-grey-light has-text-right">
              {{ $t("phone") }}
            </div>
            <div class="column is-9">
              {{ company.address.phone.code }}
              {{ company.address.phone.number }}
            </div>

            <div
              v-if="company.invoice && company.invoice.email"
              class="column is-3 has-text-grey-light has-text-right"
            >
              {{ $t("invoice_email") }}
            </div>
            <div
              v-if="company.invoice && company.invoice.email"
              class="column is-9"
            >
              {{ company.invoice.email }}
            </div>
          </div>
        </div>
        <div class="column is-6">
          <div class="columns is-multiline is-mobile is-variable is-1">
            <div class="column is-3 has-text-grey-light has-text-right">
              {{ $t("factoring") }}
            </div>
            <div class="column is-9">
              {{ company.factoring ? company.factoring : "-" }}
            </div>
            <div class="column is-3 has-text-grey-light has-text-right">
              {{ $t("vat_num") }}
            </div>
            <div class="column is-9">
              <b-tag
                v-if="company.vat && company.vat.num && company.vat.num.value"
                :type="
                  company.vat && company.vat.verified
                    ? 'is-success'
                    : company.vat && company.vat.verified != null ? 'is-danger' : ''
                "
                size="is-small"
              >
                {{ company.vat.num && company.vat.num.formatted }}
              </b-tag>
              <span v-else>-</span>
            </div>
            <div class="column is-3 has-text-grey-light has-text-right">
              {{ $t("exempted") }}
            </div>
            <div class="column is-9">
              {{ company.vat && company.vat.exempted ? $t("yes") : $t("no") }}
            </div>

            <div class="column is-3 has-text-grey-light has-text-right">
              {{ $t("incoterm") }}
            </div>
            <div class="column is-9">
              {{ company.incoterm || '-' }}
            </div>

            <div class="column is-3 has-text-grey-light has-text-right">
              {{ $tc("account_type", 1) }}
            </div>
            <div class="column is-9">
              {{ companyType.label || '-' }}
            </div>
          </div>
        </div>
      </div>
    </Card>
  </div>
</template>

<script>
export default {
  props: {
    company: { type: Object, default: null },
    countries: { type: Array, required: true },
    isValidUser: { type: Boolean, required: true },
    userId: { type: Number, required: true },
    languages: { type: Array, default: () => [] },
    types: { type: Array, default: () => [] },
    incoterms: { type: Array, default: () => [] },
    site: {type: Object, required: true},
    localIso: {type: String, required: true},
    status: {type: String, required: true},
  },
  computed:{
    companyType(){
      if(this.company.type_id) return this.types.find(t => t.id == this.company.type_id);
      return {}
    }
  }
};
</script>
