var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.$can('read', 'orders'))?_c('div',{staticClass:"column is-12"},[_c('Card',{attrs:{"title":_vm.$tc('order', 2)}},[_c('template',{slot:"actions"},[_c('b-input',{attrs:{"icon":"magnify","size":"is-small","placeholder":_vm.$t('search'),"rounded":""},on:{"input":_vm.ordersSearch},model:{value:(_vm.searchOrders),callback:function ($$v) {_vm.searchOrders=$$v},expression:"searchOrders"}})],1),_c('b-table',{staticClass:"height-auto",attrs:{"data":_vm.ordersData,"paginated":"","per-page":_vm.perPage,"current-page":_vm.currentPage,"default-sort":"ordered_at","default-sort-direction":"desc"}},[_c('b-table-column',{attrs:{"field":"id","label":_vm.$t('id'),"sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('a',{attrs:{"href":"javascript:;","sortable":""},on:{"click":function($event){return _vm.openDrawer('orderForm', {
              key: props.row.id,
              index: props.index,
              order_id: props.row.id,
              user_id: _vm.userId,
              site_id: props.row.site_id,
              warehouses: _vm.warehouses,
            })}}},[_vm._v(_vm._s(props.row.id))])]}}],null,false,1755275407)}),_c('b-table-column',{attrs:{"field":"total.value","label":_vm.$t('total'),"sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_vm._v(" "+_vm._s(props.row.total ? props.row.total.formatted : "-")+" ")]}}],null,false,2138308713)}),_c('b-table-column',{attrs:{"field":"ordered_at.datetime","label":_vm.$t('date'),"sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('span',[_vm._v(" "+_vm._s((props.row.ordered_at && props.row.ordered_at.formatted) || "-")+" "),_c('span',{staticClass:"has-text-grey-light"},[_vm._v(_vm._s(props.row.ordered_at && props.row.ordered_at.time))])])]}}],null,false,237589316)}),_c('b-table-column',{attrs:{"field":"status","label":_vm.$t('status'),"sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_vm._v(" "+_vm._s(props.row.status)+" ")]}}],null,false,2454658857)}),_c('b-table-column',{attrs:{"field":"invoice","label":_vm.$t('invoice'),"width":"80","centered":"","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(props){return [(props.row.invoice)?_c('span',{on:{"click":function($event){return _vm.downloadInvoice(props.row.id)}}},[_c('b-icon',{staticClass:"has-text-grey is-clickable",attrs:{"icon":"download","custom-size":"mdi-18px"}})],1):_c('span',[_vm._v("-")])]}}],null,false,2495243306)}),_c('EmptyBlock',{attrs:{"slot":"empty","icon":"cart-outline"},slot:"empty"})],1),_c('Pagination',{staticClass:"p-0",attrs:{"per-page":_vm.perPage,"total":_vm.ordersData.length,"current-page":_vm.currentPage},on:{"update-per-page":function (val) { return (_vm.perPage = val); },"update-current-page":function (val) { return (_vm.currentPage = val); }}})],2)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }