<template>
  <Page
    :subtitle="`${profile.fname || ' '} ${profile.lname || ''}`"
    has-actions
    :loading="!overview_loaded"
  >
    <template slot="actions">
      <b-dropdown
        v-if="isValidUser"
        aria-role="list"
        position="is-bottom-left"
        scrollable
        max-height="400px"
        append-to-body
      >
        <div slot="trigger">
          <b-button
            icon-left="dots-vertical"
            type="is-default"
            rounded
          />
        </div>

        <b-dropdown-item
          v-if="
            $can('create', 'orders') &&
              (profile.status.key == 'active' ||
                profile.status.key == 'hbounced')
          "
          aria-role="listitem"
          @click="placeOrder()"
        >
          <b-icon
            icon="cart-arrow-down"
            size="is-small"
          />
          {{ $t("place_order") }}
        </b-dropdown-item>
        <b-dropdown-item
          v-if="$can('read', 'users') && profile.status.key == 'active'"
          aria-role="listitem"
          @click="requestPassword()"
        >
          <b-icon
            icon="lock"
            size="is-small"
          />
          {{ $t("request_password") }}
        </b-dropdown-item>
        <b-dropdown-item
          v-if="
            $can('update', 'users') &&
              (profile.status.key == 'active' ||
                profile.status.key == 'hbounced')
          "
          aria-role="listitem"
          @click="suspend()"
        >
          <b-icon
            icon="account-off"
            size="is-small"
          />
          {{ $t("suspend") }}
        </b-dropdown-item>
        <b-dropdown-item
          v-if="
            $can('update', 'users') &&
              (profile.status.key == 'suspended' ||
                profile.status.key == 'inactive' ||
                profile.status.key == 'hbounced')
          "
          aria-role="listitem"
          @click="activate()"
        >
          <b-icon
            icon="refresh"
            size="is-small"
          />
          {{ $t("activate") }}
        </b-dropdown-item>
        <b-dropdown-item
          v-if="
            $can('create', 'users.business.approval') &&
              (profile.status.key == 'awaiting')
          "
          aria-role="listitem"
          @click="activate()"
        >
          <b-icon
            icon="check"
            type="is-success"
            size="is-small"
          />
          {{ $t("accept") }}
        </b-dropdown-item>
        <b-dropdown-item
          v-if="
            $can('delete', 'users.business.approval') &&
              (profile.status.key == 'awaiting')
          "
          aria-role="listitem"
          @click="confirmSuspending()"
        >
          <b-icon
            icon="close"
            type="is-danger"
            size="is-small"
          />
          {{ $t("reject") }}
        </b-dropdown-item>
        <b-dropdown-item
          v-if="$can('delete', 'users') &&
            (profile.status.key !== 'awaiting')"
          aria-role="listitem"
          @click="confirmDeleting()"
        >
          <b-icon
            icon="delete"
            type="is-danger"
            size="is-small"
          />
          {{ $t("delete") }}
        </b-dropdown-item>
      </b-dropdown>
    </template>

    <div
      v-if="overview_loaded"
      class="profile columns"
    >
      <div class="column is-3">
        <Card
          class="menu"
          content-class="p-3 columns is-multiline is-mobile m-0"
        >
          <div
            class="column is-4"
            :class="{
              'bg-deleted': profile.status.key == 'removed'
            }"
          >
            <img
              src="/img/avatars/blank.svg"
              class="avatar"
              :alt="profile.fname"
              width="100%"
            >
          </div>

          <div
            class="
              column
              is-8 is-flex is-flex-direction-column is-justify-content-center
            "
            :class="{
              'bg-deleted': profile.status.key == 'removed'
            }"
          >
            <span class="mb-1">
              <b>{{ profile.fname }} {{ profile.lname }}</b>
            </span>
            <div class="is-size-7">
              <b-icon
                icon="account-circle"
                :type="
                  profile.status.key == 'active'
                    ? 'is-success'
                    : profile.status.key == 'inactive'
                      ? 'is-warning'
                      : 'is-danger'
                "
                size="is-small"
                class="mr-1"
              />
              <span class="has-text-grey">{{ profile.status.label }}</span>

              <div class="mt-1">
                <b-dropdown
                  v-if="sites.length > 1"
                  aria-role="list"
                  position="is-bottom-left"
                  append-to-body
                >
                  <div
                    slot="trigger"
                    class="
                      is-flex is-align-items-center is-clickable
                      has-text-grey
                      is-size-7
                    "
                  >
                    <img
                      height="18"
                      width="18"
                      :src="'/img/sites/' + site.id + '.svg'"
                      class="mr-1"
                    >
                    {{ site.label }}
                    <b-icon
                      icon="chevron-down"
                      size="is-small"
                    />
                  </div>
                  <template v-for="item in sites">
                    <b-dropdown-item
                      v-if="item.user_id != profile.id"
                      :key="item.id"
                      aria-role="listitem"
                      has-link
                    >
                      <router-link
                        :to="`/users/${item.user_id}`"
                        tag="a"
                        class="is-flex"
                      >
                        <img
                          height="20"
                          width="20"
                          :src="'/img/sites/' + item.id + '.svg'"
                          class="mr-2"
                        >
                        {{ item.label }}
                      </router-link>
                    </b-dropdown-item>
                  </template>
                </b-dropdown>
                <div
                  v-else
                  slot="trigger"
                  class="is-flex is-align-items-center has-text-grey"
                >
                  <img
                    height="20"
                    width="20"
                    :src="'/img/sites/' + site.id + '.svg'"
                    class="mr-1"
                  >
                  {{ site.label }}
                </div>
              </div>
            </div>
          </div>

          <div
            v-if="isValidUser"
            class="column is-12 mt-2"
          >
            <div class="columns is-variable is-1 is-multiline is-mobile">
              <div class="column is-3">
                <span class="has-text-grey-light">{{ $t("email") }}</span>
              </div>
              <div class="column is-9 has-text-right ellipsis">
                <span>{{ profile.email }}</span>
              </div>
              <div class="column is-3">
                <span class="has-text-grey-light">{{ $t("phone") }}</span>
              </div>
              <div class="column is-9 has-text-right ellipsis">
                <span>{{ profile.phone.code || "" }}
                  {{ profile.phone.number || "-" }}</span>
              </div>
              <template v-if="company">
                <div class="column is-3">
                  <span class="has-text-grey-light">{{ $t("company") }}</span>
                </div>
                <div class="column is-9 has-text-right ellipsis">
                  <span>{{ company.company || "-" }}</span>
                </div>
                <div
                  v-if="company"
                  class="column is-5"
                >
                  <span class="has-text-grey-light">{{
                    $tc("account_type", 1)
                  }}</span>
                </div>
                <div
                  v-if="company"
                  class="column is-7 has-text-right ellipsis"
                >
                  <span>{{
                    (types.find(t => t.id == company.type_id) &&
                      types.find(t => t.id == company.type_id).label) ||
                      "-"
                  }}</span>
                </div>
              </template>
            </div>
          </div>
          <div class="column is-12">
            <hr class="m-0">
          </div>

          <div class="column is-12 p-0">
            <ul class="tabs-container no-wrap">
              <template v-for="item in tabs">
                <li
                  v-if="
                    item.hasOwnProperty('condition') ? item.condition : true
                  "
                  :key="item.value"
                  :class="{ active: item.id == tab }"
                  @click="tab = item.id"
                >
                  <b-icon
                    :icon="item.icon"
                    custom-size="mdi-18px"
                    class="mr-2"
                  />
                  {{ item.value }}
                  <b-tag
                    v-if="item.comingSoon"
                    size="is-small"
                    type="is-success"
                  >
                    {{ $t("new") }}
                  </b-tag>
                </li>
              </template>
            </ul>
          </div>
        </Card>
      </div>
      <div class="column is-9">
        <b-message

          v-if="!isValidUser"
          type="is-danger"
        >
          <div class="is-flex is-align-items-center">
            <b-icon
              icon="account"
              type="is-danger"
              class="mr-2"
            />
            <span class="mt-1">
              <b>{{ $tc("customer", 1) }} {{ $t("deleted") }}</b>
            </span>
          </div>
        </b-message>


        <Overview
          v-if="tab == 1"
          :stats="stats"
        />

        <UserInfos
          v-if="tab == 2"
          :options="{
            index: 0,
            user: profile,
            countries: countries,
            sites: sites,
            salesmen: salesmen
          }"
          :profile="profile"
          :addresses="addresses"
          :company="company"
          :countries="countries"
          :is-valid-user="isValidUser"
          :user-id="profile.id"
          :types="types"
          :incoterms="incoterms"
          :local-iso="localIso"
          :status="profile.status.key"
          :stats="stats"
        />

        <Ecommerce
          v-if="tab == 3"
          :orders="orders"
          :vouchers="vouchers"
          :brands="brands"
          :categories="categories"
          :cashback="cashback"
          :discounts="discountItems"
          :is-valid-user="isValidUser"
          :profile="profile"
          :sites="sites"
          :site="site"
          :user-id="profile.id"
          :warehouses="warehouses"
          @update-discount="updateDiscount"
        />

        <Analytics
          v-if="tab == 4"
          :profile="profile"
        />

        <Activities
          v-if="tab == 5"
          :profile-id="profile.id"
        />

        <Crm
          v-if="tab == 6"
          :user="profile"
          :site="site"
        />
      </div>
    </div>
    <b-modal
      v-model="showConfirmModal"
      :width="400"
    >
      <Card>
        <div slot="header">
          <div class="is-size-5 px py-4">
            <b>{{ $t("are_you_sure") }}</b>
          </div>
          <hr class="my-0">
        </div>
        <div class="columns is-multiline is-mobile">
          <b-input-validation
            v-model="reason"
            :label="$t('reason') + ' (' + $t('optional') + ')'"
            vid="reason"
            input-type="textarea"
            class="column is-12 mt-2"
            hide-details
            maxlength="150"
            rows="2"
            :placeholder="placeholders.user.deleteReason"
          />

          <div class="column is-12 mt-0">
            <b-button
              class="mr-4"
              type="is-danger"
              @click="confirm()"
            >
              {{ $t("confirm") }}
            </b-button>
            <b-button
              @click="cancelDelete"
            >
              {{ $t("cancel") }}
            </b-button>
          </div>
        </div>
      </Card>
    </b-modal>
  </Page>
</template>

<script>
import Overview from "@/components/modules/profile/Overview";
import UserInfos from "@/components/modules/profile/UserInfos";
import Ecommerce from "@/components/modules/profile/Ecommerce";
import Analytics from "@/components/modules/profile/Analytics";
import Activities from "@/components/modules/profile/Activities";
import Crm from "@/components/modules/profile/Crm";

export default {
  components: {
    Overview,
    UserInfos,
    Ecommerce,
    Analytics,
    Activities,
    Crm,
  },
  data() {
    return {
      tab: 1,
      tabs: [
        // {
        //   id: 1,
        //   value: this.$i18n.t("overview"),
        //   icon: "eye",
        //   condition: true
        // },
        {
          id: 2,
          value: this.$i18n.t("customer_information"),
          icon: "account",
          condition: true
        },
        {
          id: 3,
          value: this.$i18n.t("ecommerce"),
          icon: "cart",
          condition: true
        },
        {
          id: 4,
          value: this.$i18n.tc("analytic", 2),
          icon: "chart-line",
          condition: this.$can("read", "orders.analytics")
        },
        {
          id: 5,
          value: this.$i18n.tc("activity", 2),
          icon: "timeline-text",
          condition: this.$can("read", "users.activities")
        },
        {
          id: 6,
          value: this.$i18n.t("crm"),
          icon: "view-list",
          condition: this.$can("read", "users.crm"),
          comingSoon: true
        }
      ],

      overview_loaded: false,
      profile: {},
      salesmen: [],
      sites: [],
      brands: [],
      incoterms: [],
      categories: [],
      stats: {},
      discountItems: [],
      countries: [],
      addresses: [],
      orders: [],
      vouchers: [],
      site: null,
      company: {},
      statuses: [],
      types: [],
      warehouses: [],
      localIso: null,
      confirmFrom: null,
      showConfirmModal: false,
      reason: null,
    };
  },
  computed: {
    isValidUser() {
      return (
        this.profile &&
        this.profile.status &&
        this.profile.status.key != "removed"
      );
    }
  },
  watch: {
    "$route.params.user_id": function() {
      if (this.$can("read", "users")) this.loadUser();
    }
  },
  created() {
    this.updateTitle(this.$i18n.t("customer_dashboard"));
    if (this.$can("read", "users")) this.loadUser();
    this.tab = this.$route.query.tab || 2;

    // Update Company
    this.$bus.$on("update-company", company => {
      this.company = company;
    });
    // End Of Update Company

    // Delete Company
    this.$bus.$on("delete-address", index => {
      this.addresses.splice(index, 1);
    });
    // End Of Delete Company

    // Update addresses
    this.$bus.$on("update-user-address", ({ index, address }) => {
      if (index == -1) {
        this.addresses.unshift(address);
      } else {
        this.$set(this.addresses, index, address);
      }
      if (address.main) {
        let main_index = index !== -1 ? index : 0;
        let addresses = this.addresses;
        for (const key in addresses) {
          if (key != main_index) this.addresses[key]["main"] = false;
        }
      }
      this.closeDrawer();
    });
    // End Of Update Addresses

    // Update Voucher
    this.$bus.$on("save-voucher", voucher => {
      if (voucher.index !== -1)
        this.vouchers.splice(voucher.index, 1, voucher.voucher);
      else this.vouchers.unshift(voucher.voucher);
    });
    // End Of Update Voucher

    // Update User
    this.$bus.$on("update-user", profile => {
      this.profile.fname = profile.fname;
      this.profile.lname = profile.lname;
      this.profile.email = profile.email;
      this.profile.phone = profile.phone;
      this.profile.language = profile.language;
      this.profile.lang_iso = profile.language.iso;
      this.profile.salesman = profile.salesman;
      this.profile.offers = profile.offers;
      this.profile.signature = profile.signature;
      let site = this.sites.filter(s => s.id === this.profile.site.id);
      let tld = site.tlds.find(t => t.id == profile.tld.id);
      this.profile.tld = tld;
    });
    // End Of Update User

    // Update Cashback
    this.$bus.$on("save-cashback", ({ index, cashback }) => {
      if (index == -1) {
        this.cashback.unshift(cashback);
      } else {
        this.$set(this.cashback, index, cashback);
      }
    });
    // End Of Update Cashback
  },
  destroyed() {
    this.$bus.$off("update-company");
    this.$bus.$off("delete-address");
    this.$bus.$off("update-user-address");
    this.$bus.$off("update-user");
    this.$bus.$off("save-cashback");
    this.$bus.$off("save-voucher");
  },
  methods: {
    placeOrder() {
      if (this.$can("create", "orders")) {
        this.$axios
          .get("users/" + this.profile.id + "/token")
          .then(res => {
            let url = res.data.url;
            let token = res.data.token;
            let cid = res.data.cid;
            var win = window.open(
              url + "?atoken=" + token + "&ctoken=" + encodeURIComponent(cid),
              "_blank"
            );
            win.focus();
          })
          .catch(e => this.clientError(e));
      }
    },
    requestPassword() {
      if (
        this.$can("read", "users") &&
        this.isValidUser 
      ) {
        this.$axios
          .post("users/" + this.profile.id + "/password/request")
          .then(() => {
            this.notify(
              this.$i18n.t("request_successfully_sent"),
              "is-success"
            );
          })
          .catch(e => this.clientError(e));
      }
    },
    confirmSuspending() {
      this.confirmFrom = "suspend";
      this.showConfirmModal = true;
    },
    confirmDeleting() {
      this.confirmFrom = "delete";
      this.showConfirmModal = true;
    },
    confirm() {
      if(this.confirmFrom == "delete") this.SendDeleteRequest();
      if(this.confirmFrom == "suspend") this.suspend();
    },
    cancelDelete() {
      this.showConfirmModal = false;
      this.reason = null;
    },
    SendDeleteRequest() {
      if (this.$can("delete", "users")) {
        this.$axios.defaults.headers.common["X-REASON"] = this.reason;
        this.$axios
          .delete("users/" + this.profile.id)
          .then(() => {
            let removedStatus = this.statuses.find(s => s.key == "removed");
            this.profile.status = removedStatus;
            this.reason = null;
            this.showConfirmModal = false;
            this.$root.notify(this.$t("successfully_deleted"), "is-success");
          })
          .catch(e => this.clientError(e));
      }
    },
    suspend() {
      let reason = null;
      if (this.reason) reason = this.reason ;
      this.$axios
        .patch("users/" + this.profile.id + "/suspend"
        + ( this.profile.status.key == 'awaiting' ? '?origin=signup':'?origin=account' ), {reason: reason})
        .then(() => {
          let suspendedStatus = this.statuses.find(s => s.key == "inactive");
          this.profile.status = suspendedStatus;
          this.reason = null;
          this.showConfirmModal = false;
          this.$root.notify(this.$t("successfully_updated"), "is-success");
        })
        .catch(e => this.clientError(e));
    },
    activate() {
      this.$axios
        .patch("users/" + this.profile.id + "/activate"
          + ( this.profile.status.key == 'awaiting' ? '?origin=signup':'?origin=account' ))
        .then(() => {
          let activeStatus = this.statuses.find(s => s.key == "active");
          this.profile.status = activeStatus;
          this.$root.notify(this.$t("successfully_updated"), "is-success");
        })
        .catch(e => this.clientError(e));
    },
    loadUser() {
      let id = this.$route.params.user_id;

      this.overview_loaded = false;
      this.$axios
        .get("users/" + id)
        .then(res => {
          this.salesmen = res.data.filters.salesmen;
          this.incoterms = res.data.filters.incoterm || [];
          this.sites = res.data.filters.sites;
          this.brands = res.data.filters.brands;
          this.categories = res.data.filters.categories;
          this.stats = res.data.overview.user.stats;
          this.profile = res.data.overview.user.profile;
          this.localIso = res.data.overview.user.profile.local_iso
          this.discountItems = res.data.overview.user.brands_discounts;
          this.company = res.data.overview.user.company;
          this.countries = res.data.filters.countries
            ? res.data.filters.countries
            : [];
          this.statuses = res.data.filters.statuses;
          this.addresses = res.data.overview.user.addresses
            ? res.data.overview.user.addresses
            : [];
          this.types = res.data.filters.types;
          this.orders = res.data.overview.orders
            ? res.data.overview.orders
            : [];
          this.vouchers = res.data.overview.vouchers
            ? res.data.overview.vouchers
            : [];

          this.cashback = res.data.overview.cashback
            ? res.data.overview.cashback
            : [
                {
                  id: 8,
                  user_id: 431935,
                  order_id: 615913,
                  used_order_id: 615917,
                  currency: { code: "EUR" },
                  origin: "order",
                  amount: { value: 94.0, formatted: "94.0€" },
                  expires_at: { datetime: "2021-06-06 18:54:07" },
                  used: true,
                  active: true
                }
              ];

          let site = this.sites.filter(s => s.id === this.profile.site.id);
          this.site = site[0];
          this.$axios.defaults.headers.common["X-SID"] = this.profile.site.id;

          this.languages = this.site.languages;
          this.warehouses = res.data.filters.warehouses;
        })
        .catch(e => {
          this.valid_account = false;
          this.clientError(e);
        })
        .finally(() => (this.overview_loaded = true));
    },
    updateDiscount(items) {
      this.discountItems = items;
    },
    getLanguage(lang_iso) {
      let language = this.languages.filter(l => l.iso == lang_iso);
      if (language.length > 0) return language[0].label;
      else return "-";
    }
  }
};
</script>
