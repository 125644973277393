<template>
  <div
    v-on-clickaway="away"
    class="dates-dropdown month"
  >
    <span
      class="is-clickable"
      :class="{ 'has-text-primary': showMenu }"
      @click="showMenu = true"
    >
      <b-icon
        icon="calendar"
        size="is-small"
        class="is-clickable mr-1"
      />
      <span>{{ displayDate }} </span>
    </span>
    <div
      class="menu-ol"
      :class="{ active: showMenu }"
    >
      <b-datepicker
        :key="'datepicker1-' + datepickerKey"
        ref="datepicker"
        v-model="picker_date"
        size="is-small"
        type="month"
        :min-date="minDate ? new Date(minDate) : null"
        :max-date="maxDate ? new Date(maxDate) : null"
        inline
      />
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { mixin as clickaway } from "vue-clickaway2";

export default {
  mixins: [clickaway],
  props: {
    minDate: {
      type: [Date, String],
      default: "01/01/2013"
    },
    maxDate: {
      type: [Date, String],
      default: null
    }
  },
  data() {
    return {
      datepickerKey: 1,
      showMenu: false,
      displayDate: "",
      picker_date: null
    };
  },
  watch: {
    picker_date(newVal) {
      this.$emit("input", newVal);
      this.displayDate = moment(newVal).format("MMMM YYYY");
      this.away();
    }
  },
  created() {
    // this.displayDate = this.$t("choose_month");
    let startOfMonth = moment().startOf("month");
    this.displayDate = moment(startOfMonth).format("MMMM YYYY");
    this.picker_date = new Date(startOfMonth.toISOString());
  },
  methods: {
    away() {
      this.showMenu = false;
    }
  }
};
</script>
