<template>
  <div class="columns is-multiline">
    <Orders
      :orders="orders"
      :user-id="userId"
      :warehouses="warehouses"
    />
    <Discounts
      v-if="$can('read', 'users.business') && site.type === 'b2b'"
      :discounts="discounts"
      :user-id="userId"
      :is-valid-user="isValidUser"
      @update="updateDiscount"
    />
    <Vouchers
      :vouchers="vouchers"
      :is-valid-user="isValidUser"
      :profile="profile"
      :brands="brands"
      :categories="categories"
      :sites="sites"
      :site="site"
    />
    <Cashback
      :cashback="cashback"
      :is-valid-user="isValidUser"
      :profile="profile"
      :sites="sites"
      :orders="orders"
      :site="site"
    />
  </div>
</template>

<script>
import Orders from "./Cards/orders";
import Vouchers from "./Cards/Vouchers";
import Cashback from "./Cards/Cashback";
import Discounts from "./Cards/Discounts";
export default {
  components: {
    Orders,
    Vouchers,
    Cashback,
    Discounts
  },
  props: {
    orders: { type: Array, required: true },
    vouchers: { type: Array, required: true },
    cashback: { type: Array, required: true },
    discounts: { type: Array, required: true },
    isValidUser: { type: Boolean, required: true },
    profile: { type: Object, required: true },
    sites: { type: Array, required: true },
    brands: { type: Array, required: true },
    categories: { type: Array, required: true },
    site: { type: Object, required: true },
    userId: { type: Number, required: true },
    warehouses: { type: Array, required: true },
  },
  data() {
    return {};
  },
  methods: {
    updateDiscount(items) {
      this.$emit("update-discount", items);
    }
  }
};
</script>
