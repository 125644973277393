<template>
  <div class="column is-12">
    <Card
      :title="$t('customer_information')"
      header-class="mb-4"
    >
      <template slot="actions">
        <span
          v-if="$can('update', 'users') && isValidUser"
          @click="openDrawer('openUserForm', { index: 0, ...options })"
        >
          <b-icon
            icon="pencil"
            size="is-small"
            class="is-clickable"
          />
        </span>
      </template>
      <div class="columns">
        <div class="column is-6">
          <div class="columns is-multiline is-mobile is-variable is-1">
            <div class="column is-3 has-text-grey-light has-text-right">
              {{ $t("lname") }}
            </div>
            <div class="column is-9">
              {{ profile.fname + " " + profile.lname }}
            </div>
            <div class="column is-3 has-text-grey-light has-text-right">
              {{ $t("email") }}
            </div>
            <div class="column is-9">
              {{ profile.email }}
            </div>
            <div class="column is-3 has-text-grey-light has-text-right">
              {{ $t("phone") }}
            </div>
            <div class="column is-9">
              {{ profile.phone.code || "" }}
              {{
                profile.phone.number ? profile.phone.number : "-"
              }}
            </div>
            <div class="column is-3 has-text-grey-light has-text-right">
              {{ $t("created_at") }}
            </div>
            <div class="column is-9">
              {{
                (profile.created_at && profile.created_at.formatted) ||
                  "-"
              }}
              <span class="has-text-grey-light">{{
                profile.created_at && profile.created_at.time
              }}</span>
            </div>
            <div class="column is-3 has-text-grey-light has-text-right">
              {{ $tc("salesman", 1) }}
            </div>
            <div class="column is-9">
              {{ profile.salesman && `${profile.salesman.fname} ${profile.salesman.lname}` || "-" }}
            </div>
          </div>
        </div>
        <div class="column is-6">
          <div class="columns is-multiline is-mobile is-variable is-1">
            <div class="column is-3 has-text-grey-light has-text-right">
              {{ $t("origin") }}
            </div>
            <div class="column is-9">
              {{ profile.origin }}
            </div>
            <div class="column is-3 has-text-grey-light has-text-right">
              {{ $t("newsletter") }}
            </div>
            <div class="column is-9">
              {{ profile.offers ? $t("yes") : $t("no") }}
            </div>
            <div class="column is-3 has-text-grey-light has-text-right">
              {{ $t("tld") }}
            </div>
            <div class="column is-9">
              {{ tld.label }}
            </div>
            <div class="column is-3 has-text-grey-light has-text-right">
              {{ $tc("language", 1) }}
            </div>
            <div class="column is-9">
              {{ language ? language.label : profile.lang_iso }}
            </div>
            <div class="column is-3 has-text-grey-light has-text-right">
              {{ $t("last_signin") }}
            </div>
            <div class="column is-9">
              {{
                (profile.last_signin_at && profile.last_signin_at.formatted) ||
                  "-"
              }}
              <span class="has-text-grey-light">{{
                profile.last_signin_at && profile.last_signin_at.time
              }}</span>
            </div>
          </div>
        </div>
      </div>
    </Card>
  </div>
</template>

<script>
export default {
  props: {
    profile: { type: Object, required: true },
    options: { type: Object, required: true },
    sites: { type: Array, required: true },
    isValidUser: { type: Boolean, required: true }
  },
  data() {
    return {
      site: null,
      tld: null,
      language: null
    };
  },
  watch: {
    "profile.tld"(newVal) {
      this.tld = this.profile.tld;
    },
    "profile.lang_iso"(newVal) {
      this.language =
        this.site && this.site.languages.find(l => l.iso == newVal);
    }
  },
  mounted() {
    this.$bus.$on("update-user", profile => {
      this.profile.fname = profile.fname;
      this.profile.lname = profile.lname;
      this.profile.email = profile.email;
      this.profile.phone = profile.phone;
      this.profile.language = profile.language;
      this.profile.lang_iso = profile.language.iso;
      this.profile.salesman = profile.salesman;
      this.profile.offers = profile.offers;
      this.profile.signature = profile.signature;
      this.profile.tld = profile.tld;
    });
  },
  destroyed() {
    this.$bus.$off("update-user");
  },
  created() {
    this.site = this.sites.find(s => s.id == this.profile.site.id);
    this.tld = this.profile.tld;
    this.language =
      this.site &&
      this.site.languages.find(l => l.iso == this.profile.lang_iso);
  }
};
</script>
