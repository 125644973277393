<template>
  <div class="column is-12">
    <Card :title="$t('mailing')">
      <template slot="actions">
        <MonthPicker
          v-model="date_range"
          :max-date="maxDate"
        />
      </template>

      <b-table
        :loading="loading"
        :data="items"
        default-sort="date.datetime"
        default-sort-direction="desc"
        class="height-auto"
      >
        <b-table-column
          v-slot="props"
          field="subject"
          :label="$t('subject')"
          sortable
        >
          <a
            href="javascript:;"
            @click="
              openDrawer('userMailing', {
                uuid: props.row.uuid,
              })
            "
          >
            {{ props.row.subject }}
          </a>
        </b-table-column>
        <b-table-column
          v-slot="props"
          field="date.datetime"
          :label="$t('date')"
          sortable
        >
          <span v-if="props.row.date">
            {{ props.row.date.formatted }}
            <span class="has-text-grey"> {{ props.row.date.time }}</span>
          </span>
          <template v-else>
            -
          </template>
        </b-table-column>
        <EmptyBlock
          slot="empty"
          icon="mail"
        />
      </b-table>
    </Card>
  </div>
</template>

<script>
import MonthPicker from "@/components/forms/elements/MonthPicker";
import moment from "moment";

export default {
  components: {
    MonthPicker,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
    site: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: true,
      maxDate: new Date(),
      date_range: null,
      items: [],
    };
  },
  watch: {
    date_range(newVal) {
      let from = moment(newVal).startOf("month").format("YYYY-MM-DD");
      let to = moment(newVal).endOf("month").format("YYYY-MM-DD");
      if(moment(newVal).endOf("month").diff(moment()) > 0){
        from = moment().startOf("month").format("YYYY-MM-DD");
        // from = moment().add(-1, "M").format("YYYY-MM-DD");
        to = moment().format("YYYY-MM-DD");
      }
      this.getData(from, to);
    },
  },
  // created() {
  //   let from = moment().add(-1, "M").format("YYYY-MM-DD");
  //   let to = moment().format("YYYY-MM-DD");
  //   this.getData(from, to);
  // },
  methods: {
    getData(from, to) {
      this.loading = true;
      this.$axios
        .get(`users/crm/${this.user.email}/${this.site.label.toLowerCase()}?from=${from}&to=${to}`)
        .then((res) => {
          this.items = res.data.list;
        })
      .catch((e) => this.clientError(e))
      .finally(() => (this.loading = false));
    },
  },
};
</script>

<style>
</style>
