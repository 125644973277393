<template>
  <div
    v-if="$can('read', 'users.cashback')"
    class="column is-12"
  >
    <Card :title="$t('cashback')">
      <template slot="actions">
        <span
          v-if="isValidUser && $can('create', 'users.cashback')"
          @click="openForm(-1, {})"
        >
          <b-icon
            icon="plus"
            size="is-small"
            class="is-clickable"
          />
        </span>
      </template>
      <b-table
        :data="cashback"
        paginated
        :per-page="perPage"
        :current-page="currentPage"
        default-sort="id"
        :row-class="(row, index) => !row.active && 'bg-deleted'"
        class="height-auto"
      >
        <b-table-column
          v-slot="props"
          field="id"
          :label="$t('id')"
          sortable
        >
          <a
            v-if="
              $can('read', 'users.cashback') || $can('update', 'users.cashback')
            "
            href="javascript:;"
            @click="openForm(props.index, props.row)"
          >{{ props.row.id }}</a>
          <span v-else>{{ props.row.id }}</span>
        </b-table-column>
        <b-table-column
          v-slot="props"
          field="order_id"
          :label="$tc('order', 1)"
          sortable
        >
          <a
            v-if="props.row.order_id"
            href="javascript:;"
            sortable
            @click="
              openDrawer('orderForm', {
                key: props.row.order_id,
                index: props.index,
                order_id: props.row.order_id,
                user_id: profile.id,
                site_id: profile.site && profile.site.id
              })
            "
          >
            {{ props.row.order_id }}
          </a>
          <span v-else>
            -
          </span>
        </b-table-column>
        <b-table-column
          v-slot="props"
          field="used_order_id"
          :label="$t('used')"
          sortable
        >
          <b-icon
            v-if="props.row.used"
            icon="check"
            type="is-success"
            custom-size="mdi-18px"
          />
          <a
            v-if="props.row.used_order_id"
            href="javascript:;"
            sortable
            @click="
              openDrawer('orderForm', {
                key: props.row.used_order_id,
                index: props.index,
                order_id: props.row.used_order_id,
                user_id: profile.id,
                site_id: profile.site && profile.site.id
              })
            "
          >
            {{ props.row.used_order_id || "-" }}
          </a>
          <span v-else>-</span>
        </b-table-column>
        <b-table-column
          v-slot="props"
          field="amount.value"
          :label="$t('amount')"
          sortable
        >
          {{
            props.row.amount ? props.row.amount.formatted : "-"
          }}
        </b-table-column>
        <b-table-column
          v-slot="props"
          field="expire_at.datetime"
          :label="$t('expire_at')"
          sortable
        >
          <span>
            {{ (props.row.expire_at && props.row.expire_at.formatted) || "-" }}
            <span class="has-text-grey-light">{{
              props.row.expire_at && props.row.expire_at.time
            }}</span>
          </span>
        </b-table-column>
        <b-table-column
          v-if="isValidUser"
          v-slot="props"
          field="actions"
          width="80"
          centered
          :label="$t('actions')"
        >
          <b-dropdown
            aria-role="list"
            position="is-bottom-left"
            scrollable
            max-height="400px"
            append-to-body
          >
            <div slot="trigger">
              <b-icon
                icon="dots-vertical"
                size="is-small"
                class="is-clickable"
              />
            </div>

            <b-dropdown-item
              v-if="$can('update', 'users.cashback')"
              aria-role="listitem"
              @click="openForm(props.index, props.row)"
            >
              <b-icon
                icon="eye"
                size="is-small"
              />
              {{ $t("view") }}
            </b-dropdown-item>
            <b-dropdown-item
              v-if="props.row.active && $can('delete', 'users.cashback')"
              aria-role="listitem"
              @click="deleteCashback(props.index, props.row.id)"
            >
              <b-icon
                icon="delete"
                type="is-danger"
                size="is-small"
              />
              {{ $t("delete") }}
            </b-dropdown-item>
            <b-dropdown-item
              v-if="!props.row.active && $can('update', 'users.cashback')"
              aria-role="listitem"
              @click="restoreCashback(props.index, props.row.id)"
            >
              <b-icon
                icon="restore"
                size="is-small"
                type="is-success"
              />
              {{ $t("restore") }}
            </b-dropdown-item>
          </b-dropdown>
        </b-table-column>
        <EmptyBlock
          slot="empty"
          icon="currency-usd"
        />
      </b-table>
      <Pagination
        class="p-0"
        :per-page="perPage"
        :total="cashback.length"
        :current-page="currentPage"
        @update-per-page="val => (perPage = val)"
        @update-current-page="val => (currentPage = val)"
      />
    </Card>
  </div>
</template>

<script>
export default {
  props: {
    cashback: { type: Array, required: true },
    isValidUser: { type: Boolean, required: true },
    profile: { type: Object, required: true },
    sites: { type: Array, required: true },
    orders: { type: Array, default: () => [] },
    site: { type: Object, required: true }
  },
  data() {
    return {
      currentPage: 1,
      perPage: 5
    };
  },
  methods: {
    deleteCashback(index, cashback_id) {
      if (this.$can("delete", "users.cashback")) {
        this.$axios
          .delete("users/" + this.profile.id + "/cashback/" + cashback_id)
          .then(() => {
            this.cashback[index]["active"] = false;

            // Delete cashback, move to latest
            let cashback_copy = JSON.parse(
              JSON.stringify(this.cashback[index])
            );
            this.cashback.splice(index, 1);
            this.cashback.push(cashback_copy);
          })
          .catch(e => this.clientError(e));
      }
    },
    restoreCashback(index, cashback_id) {
      if (this.$can("update", "users.cashback")) {
        this.$axios
          .patch(
            "users/" + this.profile.id + "/cashback/" + cashback_id + "/restore"
          )
          .then(() => {
            this.cashback[index]["active"] = true;
            this.cashback.unshift(this.cashback[index]);
            this.cashback.splice(index + 1, 1);
            this.updated_cashback = 0;

            let self = this;
            setTimeout(function() {
              self.updated_cashback = null;
            }, 2000);
          })
          .catch(e => this.clientError(e));
      }
    },
    openForm(index, cashback, action = "cu") {
      if (
        this.$can("read", "users.cashback") ||
        this.$can("update", "users.cashback")
      ) {
        // cu : create+update
        let default_site_id = this.site.id;

        let warehouses = this.site.warehouses;
        let cashback_form = { ...cashback };
        if (index === -1) {
          cashback_form = {
            ...cashback
          };
        }
        let options = {
          index: index,
          cashback: cashback_form,
          default_site_id: default_site_id,
          sites: this.sites,
          orders: this.orders,
          warehouses: warehouses,
          user_id: this.profile.id,
          action: action
        };
        this.openDrawer("cashbackForm", options);
      }
    }
  }
};
</script>
