<template>
  <div class="column is-12">
    <Card :title="$t('address_book')">
      <template
        v-if="isValidUser && $can('update', 'users')"
        slot="actions"
      >
        <span
          @click="
            openDrawer('addressForm', {
              index: -1,
              userId: userId,
              address: {
                phone: { code: null, number: null },
                country: { iso: null },
              },
              action: 'create',
              countries: countries,
            })
          "
        >
          <b-icon
            icon="plus"
            size="is-small"
            class="is-clickable"
          />
        </span>
      </template>
      <b-table
        :data="addresses"
        paginated
        :per-page="perPage"
        :current-page="currentPage"
        class="height-auto"
        default-sort="id"
      >
        <b-table-column
          v-slot="props"
          field="id"
          width="80"
          :label="$t('id')"
          sortable
        >
          <a
            v-if="$can('update', 'users')"
            href="javascript:;"
            @click="openForm(props.index, props.row)"
          >{{ props.row.id }}</a>
          <span v-else>{{ props.row.id }}</span>
        </b-table-column>
        <b-table-column
          v-slot="props"
          field="fname"
          :label="$tc('name', 1)"
          sortable
        >
          <span>
            <b-icon
              v-if="props.row.status === 'updated'"
              icon="cached"
              type="is-danger"
              size="is-small"
            />
            <b-icon
              v-else-if="props.row.status === 'deleted'"
              icon="delete"
              type="is-danger"
              size="is-small"
            />
            <b-icon
              v-else-if="props.row.main"
              small
              color="success"
              icon="home"
              type="is-success"
              size="is-small"
            />
            {{ props.row.fname }} {{ props.row.lname }}
            <span
              class="is-block"
            >{{ props.row.phone.code }} {{ props.row.phone.number }}</span>
          </span>
        </b-table-column>
        <b-table-column
          v-slot="props"
          field="address1"
          :label="$t('address')"
          sortable
        >
          <span>
            {{ props.row.address1 }}
            <span
              v-if="props.row.address2"
              class="is-block"
            >{{
              props.row.address2
            }}</span>
          </span>
        </b-table-column>
        <b-table-column
          v-slot="props"
          field="city"
          :label="$t('address2')"
          sortable
        >
          <span>
            {{ props.row.city }}, {{ props.row.zip }}
            <span class="is-block">{{ props.row.country.country }}</span>
          </span>
        </b-table-column>
        <b-table-column
          v-if="isValidUser"
          v-slot="props"
          field="actions"
          centered
          width="80"
          :label="$t('actions')"
        >
          <b-dropdown
            aria-role="list"
            position="is-bottom-left"
            scrollable
            max-height="400px"
            append-to-body
          >
            <div slot="trigger">
              <b-icon
                icon="dots-vertical"
                size="is-small"
                class="is-clickable"
              />
            </div>

            <b-dropdown-item
              v-if="$can('update', 'users')"
              aria-role="listitem"
              @click="openForm(props.index, props.row)"
            >
              <b-icon
                icon="pencil"
                size="is-small"
              />
              {{ $t("edit") }}
            </b-dropdown-item>

            <b-dropdown-item
              v-if="$can('update', 'users')"
              aria-role="listitem"
              @click="setMain(props.index, props.row.id)"
            >
              <b-icon
                icon="home"
                size="is-small"
              />
              {{ $t("main_address") }}
            </b-dropdown-item>

            <b-dropdown-item
              v-if="$can('delete', 'users')"
              aria-role="listitem"
              @click="confirmDeleting(props.index, props.row.id)"
            >
              <b-icon
                icon="delete"
                size="is-small"
                type="is-danger"
              />
              {{ $t("delete") }}
            </b-dropdown-item>
          </b-dropdown>
        </b-table-column>
        <EmptyBlock
          slot="empty"
          icon="map-marker"
        />
      </b-table>
      <Pagination
        class="p-0"
        :per-page="perPage"
        :total="addresses.length"
        :current-page="currentPage"
        @update-per-page="(val) => (perPage = val)"
        @update-current-page="(val) => (currentPage = val)"
      />
    </Card>
  </div>
</template>

<script>
export default {
  props: {
    addresses: { type: Array, required: true },
    countries: { type: Array, required: true },
    userId: { type: Number, required: true },
    isValidUser: { type: Boolean, required: true }
  },
  data() {
    return {
      currentPage: 1,
      perPage: 5,
    };
  },
  methods: {
    openForm(index, address) {
      let address_form = { ...address, user_id: this.userId };
      let options = {
        index: index,
        address: address_form,
        countries: this.countries,
        userId: this.userId,
      };
      this.openDrawer("addressForm", options);
    },
    setMain(index, address_id) {
      this.$axios
        .put("users/" + this.userId + "/addresses/" + address_id, {
          main: true,
        })
        .then(() => {
          this.$bus.$emit("update-user-address", {
            index: index,
            address: { ...this.addresses[index], main: true },
          });
        })
        .catch((e) => this.clientError(e));
    },
    confirmDeleting(index, address_id) {
      this.$buefy.dialog.confirm({
        message: `<b>${this.$t("are_you_sure")}</b>`,
        confirmText: this.$t("confirm"),
        cancelText: this.$t("cancel"),
        type: "is-danger",
        onConfirm: () => this.delete(index, address_id),
      });
    },
    delete(index, address_id) {
      this.$axios
        .delete("users/" + this.userId + "/addresses/" + address_id)
        .then(() => {
          this.$bus.$emit("delete-address", index);
        })
        .catch((e) => this.clientError(e));
    },
  },
};
</script>
