<template>
  <div
    v-if="$can('read', 'orders.vouchers')"
    class="column is-12"
  >
    <Card :title="$tc('voucher', 2)">
      <template slot="actions">
        <b-input
          v-model="searchVouchers"
          icon="magnify"
          size="is-small"
          :placeholder="$t('search')"
          rounded
          :class="{ 'mr-2': isValidUser && $can('create', 'orders.vouchers') }"
          @input="vouchersSearch"
        />
        <span
          v-if="isValidUser && $can('create', 'orders.vouchers')"
          @click="openForm(-1, {})"
        >
          <b-icon
            icon="plus"
            size="is-small"
            class="is-clickable"
          />
        </span>
      </template>
      <b-table
        :data="vouchersData"
        paginated
        :per-page="perPage"
        :current-page="currentPage"
        class="height-auto"
        default-sort="id"
      >
        <b-table-column
          v-slot="props"
          field="id"
          :label="$t('id')"
          sortable
        >
          <a
            v-if="
              $can('read', 'orders.vouchers') ||
                $can('update', 'orders.vouchers')
            "
            href="javascript:;"
            @click="openForm(props.index, props.row)"
          >{{ props.row.id }}</a>
          <span v-else>{{ props.row.id }}</span>
        </b-table-column>

        <b-table-column
          v-slot="props"
          field="amount.value"
          :label="$t('discount')"
          sortable
        >
          {{
            props.row.amount ? props.row.amount.formatted : "-"
          }}
        </b-table-column>

        <b-table-column
          v-slot="props"
          field="voucher"
          :label="$tc('code', 1)"
          sortable
        >
          <span>
            <b-icon
              v-if="props.row.used"
              icon="check"
              type="is-success"
              custom-size="mdi-18px"
              class="mr-1"
            />
            {{ props.row.voucher }}
          </span>
        </b-table-column>

        <b-table-column
          v-slot="props"
          field="ends_at.datetime"
          :label="$t('expire_at')"
          sortable
        >
          <span>
            {{ (props.row.ends_at && props.row.ends_at.formatted) || "-" }}
            <span class="has-text-grey-light">{{
              props.row.ends_at && props.row.ends_at.time
            }}</span>
          </span>
        </b-table-column>

        <b-table-column
          v-slot="props"
          field="active"
          :label="$tc('active', 1)"
          sortable
        >
          <b-icon
            v-if="props.row.active"
            icon="check"
            type="is-success"
            custom-size="mdi-18px"
          />

          <b-icon
            v-else
            icon="close"
            type="is-danger"
            custom-size="mdi-18px"
          />
        </b-table-column>

        <b-table-column
          v-if="isValidUser"
          v-slot="props"
          centered
          field="actions"
          width="80"
          :label="$t('actions')"
        >
          <b-dropdown
            aria-role="list"
            position="is-bottom-left"
            scrollable
            max-height="400px"
            append-to-body
          >
            <div slot="trigger">
              <b-icon
                icon="dots-vertical"
                size="is-small"
                class="is-clickable"
              />
            </div>

            <b-dropdown-item
              v-if="$can('read', 'orders.vouchers')"
              aria-role="listitem"
              @click="openForm(props.index, props.row, 'r')"
            >
              <b-icon
                icon="eye"
                size="is-small"
              />
              {{ $t("view") }}
            </b-dropdown-item>
            <!-- <b-dropdown-item
              v-if="$can('update', 'orders.vouchers')"
              aria-role="listitem"
              @click="openForm(props.index, props.row)"
            >
              <b-icon icon="pencil" size="is-small" />
              {{ $t("edit") }}
            </b-dropdown-item> -->
            <b-dropdown-item
              v-if="props.row.active && $can('delete', 'orders.vouchers')"
              aria-role="listitem"
              @click="deleteVoucher(props.index, props.row.id)"
            >
              <b-icon
                icon="delete"
                type="is-danger"
                size="is-small"
              />
              {{ $t("delete") }}
            </b-dropdown-item>
            <b-dropdown-item
              v-if="!props.row.active && $can('update', 'orders.vouchers')"
              aria-role="listitem"
              @click="restoreVoucher(props.index, props.row.id)"
            >
              <b-icon
                icon="restore"
                size="is-small"
                type="is-success"
              />
              {{ $t("restore") }}
            </b-dropdown-item>
          </b-dropdown>
        </b-table-column>
        <EmptyBlock
          slot="empty"
          icon="ticket-percent-outline"
        />
      </b-table>
      <Pagination
        class="p-0"
        :per-page="perPage"
        :total="vouchersData.length"
        :current-page="currentPage"
        @update-per-page="val => (perPage = val)"
        @update-current-page="val => (currentPage = val)"
      />
    </Card>
  </div>
</template>

<script>
export default {
  props: {
    vouchers: { type: Array, required: true },
    isValidUser: { type: Boolean, required: true },
    profile: { type: Object, required: true },
    sites: { type: Array, required: true },
    brands: { type: Array, required: true },
    categories: { type: Array, required: true },
    site: { type: Object, required: true }
  },
  data() {
    return {
      searchVouchers: "",
      vouchersData: [],
      currentPage: 1,
      perPage: 5,
      perPages: [5, 10, 20]
    };
  },
  created() {
    this.vouchersSearch();
  },
  methods: {
    deleteVoucher(index, voucher_id) {
      if (this.$can("delete", "orders.vouchers")) {
        this.$axios
          .delete("ecommerce/vouchers/" + voucher_id)
          .then(() => {
            this.vouchers[index]["active"] = false;

            // Delete voucher, move to latest
            let voucher_copy = JSON.parse(JSON.stringify(this.vouchers[index]));
            this.vouchers.splice(index, 1);
            this.vouchers.push(voucher_copy);
          })
          .catch(e => this.clientError(e));
      }
    },
    restoreVoucher(index, voucher_id) {
      if (this.$can("update", "orders.vouchers")) {
        this.$axios
          .put("ecommerce/vouchers/" + voucher_id, { active: true })
          .then(response => {
            this.vouchers.unshift(response.data);
            this.vouchers.splice(index + 1, 1);
            this.updated_voucher = 0;

            let self = this;
            setTimeout(function() {
              self.updated_voucher = null;
            }, 2000);
          })
          .catch(e => this.clientError(e));
      }
    },
    openForm(index, voucher, action = "cu") {
      if (
        this.$can("read", "orders.vouchers") ||
        this.$can("update", "orders.vouchers")
      ) {
        // cu : create+update
        let default_site_id = this.site.id;
        let warehouses = this.site.warehouses;
        let voucher_form = { ...voucher };
        if (index === -1) {
          voucher_form = {
            ...voucher,
            min_amount: { value: null },
            amount: { value: null },
            currency: { code: null }
          };
        }
        let options = {
          key: voucher.id + index,
          index: index,
          voucher: voucher_form,
          default_site_id: default_site_id,
          sites: this.sites,
          warehouses: warehouses,
          user_id: this.profile.id,
          brands: this.brands,
          categories: this.categories,
          action: action,
          from: "profile"
        };
        this.openDrawer("voucherForm", options);
      }
    },
    vouchersSearch() {
      if (this.searchVouchers.length > 0) {
        let vouchers = [...this.vouchers];
        this.vouchersData = vouchers.filter(obj => {
          let inId =
            `${obj.id}`
              .toLowerCase()
              .indexOf(this.searchVouchers.toLowerCase()) != -1;
          let inVoucher =
            obj.voucher
              .toLowerCase()
              .indexOf(this.searchVouchers.toLowerCase()) != -1;
          let inAmount = obj.amount
            ? obj.amount.formatted
                .toLowerCase()
                .indexOf(this.searchVouchers.toLowerCase()) != -1
            : false;
          return inId || inVoucher || inAmount;
        });
      } else {
        this.vouchersData = this.vouchers;
      }
    }
  }
};
</script>
