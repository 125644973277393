<template>
  <div class="column is-12">
    <Card :title="$t('discount_brands')">
      <template slot="actions">
        <b-input
          v-model="searchDiscounts"
          icon="magnify"
          size="is-small"
          :placeholder="$t('search')"
          rounded
          @input="discountSearch"
        />
        <b-dropdown
          v-if="isValidUser"
          class="ml-2"
          aria-role="list"
          position="is-bottom-left"
          scrollable
          max-height="400px"
          append-to-body
        >
          <div slot="trigger">
            <b-icon
              icon="dots-vertical"
              size="is-small"
              class="is-clickable"
            />
          </div>

          <b-dropdown-item
            aria-role="listitem"
            @click="applyDiscounts"
          >
            <b-icon
              icon="sale"
              size="is-small"
            />
            {{ $t("apply_mask") }}
          </b-dropdown-item>
          <b-dropdown-item
            aria-role="listitem"
            @click="deleteDiscounts"
          >
            <b-icon
              icon="delete"
              type="is-danger"
              size="is-small"
            />
            {{ $t("delete") }}
          </b-dropdown-item>
        </b-dropdown>
      </template>

      <b-table
        :data="discountsData"
        paginated
        :per-page="perPage"
        :current-page="currentPage"
        class="height-auto"
        default-sort-direction="DESC"
        default-sort="discount.user"
      >
        <b-table-column
          v-slot="props"
          field="label"
          sortable
          :label="$tc('brand', 1)"
        >
          {{
            props.row.label
          }}
        </b-table-column>
        <b-table-column
          v-if="isValidUser"
          v-slot="props"
          field="discount.user"
          :label="$tc('discount', 1)"
          cell-class="editable"
          sortable
        >
          <span
            class="edit"
            @click="openInput(props.row.id, props.row.discount.user)"
          >
            <input
              :placeholder="$t('discount')"
              inputType="number"
              step=".01"
              autofocus
              :name="'discount' + props.row.id"
              :value="props.row.discount.user"
              :class="
                editDiscount === null || editDiscount !== props.row.id
                  ? ''
                  : 'show'
              "
              @blur="editDiscount = null"
              @keyup.enter="updateDiscount($event, props.index, props.row)"
            >
            {{ props.row.discount.user ? `${props.row.discount.user}%` : "-" }}
          </span>
        </b-table-column>
        <b-table-column
          v-else
          v-slot="props"
          field="discount.user"
          :label="$tc('discount', 1)"
          sortable
        >
          {{ props.row.discount.user ? `${props.row.discount.user}%` : "-" }}
        </b-table-column>
        <EmptyBlock
          slot="empty"
          icon="currency-usd-off"
        />
      </b-table>
      <Pagination
        class="p-0"
        :per-page="perPage"
        :total="discounts.length"
        :current-page="currentPage"
        @update-per-page="val => (perPage = val)"
        @update-current-page="val => (currentPage = val)"
      />
    </Card>
  </div>
</template>

<script>
export default {
  props: {
    discounts: { type: Array, required: true },
    userId: { type: [String, Number], required: true },
    isValidUser: { type: Boolean, required: true },
  },
  data() {
    return {
      searchDiscounts: "",
      discountsData: [],
      currentPage: 1,
      perPage: 5,
      perPages: [5, 10, 20],
      editDiscount: null,
      discountValue: null
    };
  },
  watch:{
    discounts(){
      this.discountSearch();
    }
  },
  created() {
    this.discountSearch();
  },
  methods: {
    applyDiscounts() {
      this.$axios
        .patch(`users/${this.userId}/brands_discounts/default`)
        .then(res => {
          this.$emit("update", res.data);
        })
        .catch(e => this.$root.clientError(e));
    },
    deleteDiscounts() {
      this.$axios
        .delete(`users/${this.userId}/brands_discounts`)
        .then(res => {
          this.$emit("update", res.data);
        })
        .catch(e => this.$root.clientError(e));
    },
    openInput(item_id, value) {
      if (this.$can("update", "users.business")) {
        this.discountValue = value;
        this.editDiscount = item_id;
      }
    },
    updateDiscount(e, index, item) {
      let data = {};
      data.discount = e.target && e.target.value && parseFloat(e.target.value);
      this.$axios
        .put(`users/${this.userId}/brands_discounts/${item.id}`, data)
        .then(res => {
          this.$emit("update", res.data);
          this.editDiscount = null;
        })
        .catch(e => this.$root.clientError(e));
    },
    discountSearch() {
      if (this.searchDiscounts.length > 0) {
        let discounts = [...this.discounts];
        this.discountsData = discounts.filter(obj => {
          let inLabel =
            `${obj.label}`
              .toLowerCase()
              .indexOf(this.searchDiscounts.toLowerCase()) != -1;
          let inDiscount =
            `${obj.discount.user && obj.discount.user}`
              .toLowerCase()
              .indexOf(this.searchDiscounts.toLowerCase()) != -1;
          return inLabel || inDiscount;
        });
      } else {
        this.discountsData = this.discounts;
      }
    }
  }
};
</script>
