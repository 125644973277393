<template>
  <div class="edit-profile columns is-multiline">
    <Overview
      :stats="stats"
    />

    <User
      :profile="profile"
      :sites="options.sites"
      :is-valid-user="isValidUser"
      :options="options"
    />
    <Company
      v-if="
        $can('read', 'users.business') && site.type === 'b2b'
      "
      :company="company"
      :countries="countries"
      :is-valid-user="isValidUser"
      :user-id="userId"
      :languages="languages"
      :types="types"
      :incoterms="incoterms"
      :site="site"
      :local-iso="localIso"
      :status="status"
    />
    <Addresses
      :addresses="addresses"
      :countries="countries"
      :user-id="userId"
      :is-valid-user="isValidUser"
    />
  </div>
</template>

<script>
import User from "./Cards/User";
import Company from "./Cards/Company";
import Addresses from "./Cards/Addresses";
import Overview from "@/components/modules/profile/Overview";

export default {
  components: {
    User,
    Company,
    Addresses,
    Overview
  },
  props: {
    options: { type: Object, required: true },
    addresses: { type: Array, required: true },
    company: { type: Object, default: null },
    countries: { type: Array, required: true },
    isValidUser: { type: Boolean, required: true },
    userId: { type: Number, required: true },
    profile: { type: Object, required: true },
    types: { type: Array, default: () => [] },
    incoterms: { type: Array, default: () => [] },
    localIso: {type: String, required: true},
    status: {type: String, required: true},
    stats: {type: Object, required: true},
  },
  data() {
    return {
      languages: []
    };
  },
  created() {
    this.site = this.options.sites.find(s => s.id == this.profile.site.id);
    this.languages = this.site.languages;
  }
};
</script>
