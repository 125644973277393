<template>
  <ActivityComponent
    :id-types="id_types"
    :headers="headers"
    :user-id="profileId"
    permission="users.activities"
  />
</template>

<script>
import ActivityComponent from "@/components/modules/Activities/Index";
export default {
  components: {
    ActivityComponent
  },
  props: {
    profileId: {
      type: [String, Number],
      required: true
    }
  },
  data() {
    return {
      id_types: [
        {
          key: "order",
          label: this.$tc("order", 1)
        },
        {
          key: "ticket",
          label: this.$tc("ticket", 1)
        },
        {
          key: "pack",
          label: this.$tc("pack", 1)
        },
        {
          key: "sku",
          label: this.$tc("sku", 1)
        },
        {
          key: "voucher",
          label: this.$tc("voucher", 1)
        },
      ],
      headers: [
        {
          value: "activity_id",
          label: this.$t("id"),
          width: "100",
          sortable: true
        },
        {
          value: "activity",
          label: this.$tc("activity", 1),
          width: "250",
          sortable: true
        },
        {
          value: "tld",
          label: this.$tc("tld", 1),
          width: "80"
        },
        {
          value: "agent",
          label: this.$tc("agent", 1),
          width: "160"
        },    
        {
          value: "details",
          label: this.$t("details")
        },
        {
          value: "created_at",
          label: this.$t("date"),
          width: "180",
          sortable: true
        },
        {
          value: "ip",
          label: this.$t("ip"),
          hasAgent: true,
          width: "160",
          sortable: true
        }
      ]
    };
  }
};
</script>
